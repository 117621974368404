/* ContactForm.css */
.contact-form-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  
  .contact-form h2 {
    grid-column: 1 / -1;
    text-align: center;
    margin-bottom: 20px;
  }

  .alertMsgContact{
    color: var(--btn-icon-clr);
    font-weight:bolder;
    padding-bottom: 20px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #b2b2b2;
    border-radius: 5px;
  }
  
  .contact-form input[type="text"]:nth-child(3),
  .contact-form input[type="email"]:nth-child(4),
  .contact-form textarea {
    grid-column: 1 / -1;
  }
  
  .contact-form button {
    grid-column: 1 / -1;
    padding: 10px;
    background-color:var(--btn-icon-clr);
    color: var(--nav-b-clr);
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
  }
  
  .contact-form button:hover {
    background-color: #3498db;
  }
  