:root {
  --nav-b-clr: #f7feff;
  --nav-b-clr-solid: #89f7fe;
  --footer-clr: #2c3e50;
  --primary-logo-brand-clr: #004AAD;
  --seconday-logo-brand-clr: #002f6d;
  --btn-icon-clr: rgb(238, 83, 0);
  --lighter-secondary-logo-brand-clr: #31363c;
  --header-dark: #0c1520;
  --text-p-clr: #333;
  --bg-font-size-title: 32px;
  --md-font-size-title: 24px;
  --sm-font-size-text: 18px;
  --mobile-font-size-title: 20px;
  --mobile-font-size-text: 14px;
  --px-gap: 40px;
  --logo-margin: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans Ethiopic', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.container {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

.btn-apo-eri {
  background-color: var(--btn-icon-clr);
  border: none;
  color: #e7fdff;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.btn-apo-eri:hover {
  background-color: #3498db;
}

.logo-home-container video {
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  position: relative;
  z-index: -1000;
}

.logo-home-container::after {
  background: rgba(48, 35, 35, 0.35);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 15s ease;

}

.logo-home-container {
  position: relative;
  display: flex;
}

.logo-home-container div {
  width: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.logo-home-container h1 {
  font-size: 300%;
  color: aliceblue;
  font-weight: 300;
}

.logo-home-container h2 {
  font-size: 150%;
  padding-top: 1rem;
  color: var(--header-dark);
}

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.page-header h1 {
  font-size: var(--bg-font-size-title);
  color: var(--lighter-secondary-logo-brand-clr);
}

.contact-us {
  padding-top: 2rem;
}

/* ------------------------------------------ */
.vision-container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.vision-section {
  padding: var(--px-gap) 5px var(--px-gap) 5px ;
  width: 100%;
  max-width: 600px;
  min-height: auto;
}

.vision-img {
  width: 30%;
  height: auto;
}

.vision-vision {
  color: #0CC0DF;
}

.vision-growth {
  color: #004AAD;
}

.vision-unity {
  color: #FFBD59;
}

.vision-faith {
  color: #474646;
}

.vision-title {
  font-size: var(--md-font-size-title);
  font-weight: bold;
  margin: 20px 0 20px 0;
}

.vision-text {
  font-size: var(--sm-font-size-text);
  color: var(--lighter-secondary-logo-brand-clr);
  line-height: 1.6;
}

/* ----------------------------------------------- */

.gradiend-box {
  background: linear-gradient(to bottom, #a0f1ff, #ffe4bd);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--px-gap);
  justify-content: center;
  align-items: start;
  padding: var(--px-gap) 5px var(--px-gap) 5px ;
  max-width: 1200px;
  margin: 0 auto;
}

.service-details {
  font-size: var(--sm-font-size-text);
  transition: transform 0.3s ease;
  padding: 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-details:hover {
  transform: scale(1.05);
}

.service-details .title {
  font-size: var(--md-font-size-title);
  font-weight: 150%;
  margin-top: 10px;
}

.service-details .hours {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: var(--text-p-clr);

}

.service-details .icon {
  color: var(--btn-icon-clr);
  margin-right: 10px;
}

.service-details .link {
  margin-top: 10px;
  text-decoration: none;
  color: var(--text-p-clr);
}

.image-service-hours img {
  width: 100%;
  height: auto;
}

.image-service-details {
  position: relative;
  
}

.image-service-details img {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
}

.image-service-details:hover img {
  filter: brightness(50%);
}

.service-details .plus-link {
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: var(--btn-icon-clr);
  background-color: var(--nav-b-clr);
  border: none;
  border-radius: 100%;
  cursor: pointer;
  padding: 10px;
  line-height: 25px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-service-details:hover .plus-link {
  opacity: 1;
  visibility: visible;
}

.service-details:hover .title {
  color: var(--btn-icon-clr);
}


.service-details .text {
  font-size: var(--sm-font-size-text);
  margin-top: 5px;
  color: var(--text-p-clr);
}

.service-details:hover .link {
  color: #3498db;
}

.image-service-details::after {
  background: rgba(48, 35, 35, 0.35);
  content: "";
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-service-details:hover::after {
  opacity: 1;
}



/* ----------------------------------------------------- */

/* --------------------------------------------------------- */

@media only screen and (min-width:900px) {
  .logo-home-container {
    height: 750px;
  }
}

@media only screen and (max-width:1200px) {
  .page-header h1 {
    font-size: var(--mobile-font-size-title);
    margin-left: 10px;
    text-align: center;
  }

  .mission-content {
    font-size: var(--mobile-font-size-text);
    flex-direction: column;
    padding: 0 0 0 1rem;

  }

  .grid-vision {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container {
    grid-template-columns: (2, 1fr);
    padding: 40px 5% 40px 5%;
  }

}

@media only screen and (max-width:780px) {
  .logo-home-container div {
    width: 100%;
  }
  
  .logo-home-container h1 {
    font-size: 6vw;
  }

  .logo-home-container h2 {
    font-size: 4vw;

  }

  .page-section {
    font-size: var(--mobile-font-size-text);
  }



  .grid-container {
    grid-template-columns: 1fr;
    padding: 40px 5% 40px 5%;
  }
}

@media screen and (max-width:450px) {
  .page-section {
    font-size: 10px;
  }

  .logo-verse,
  .page-header {
    font-size: 80%;
  }

  .vision-title,
  .service-details .title {
    font-size: var(--mobile-font-size-title);

  }

  .vision-text,
  .service-details .hours,
  .service-details .location,
  .service-details .text {
    font-size: var(--mobile-font-size-text);
  }

  /* positioning the .vision-img on the left and stack the .vision-title on top of the .vision-text on the right  */
  .grid-vision {
    display: grid;
    grid-template-columns: 1fr;
  }

  .vision-container {
    flex-direction: column;
    margin: 0 auto;
    text-align: start;
  }

  .vision-section {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }

  .vision-img {
    width: 20%;
    height: auto;
    order: 1;
    margin: 0 10px 10px 0;
  }

  .vision-text-container {
    display: flex;
    flex-direction: column;
    order: 2;
    padding: 0;
    align-items: flex-start;
  }

  .vision-title {
    margin-bottom: 0;
    margin-top: 0;
  }
}