.book-container{
  margin: 0 auto; /* Centers the grid container */
  max-width: 800px;
  padding: 2rem .5rem 0 0.5rem;
  margin-bottom: 20px;
}
.book-preview {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .book-preview:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .book-cover img {
    width: 150px;
    height: auto;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .book-info {
    flex-grow: 1;
  }
  
  .book-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .book-description {
    font-size: 1rem;
    color: rgb(174, 174, 174);
    margin-bottom: 20px;
  }

  .book-author{
    padding-bottom: 20px;
  }

  .book-info a{
    text-decoration: none;
  }
  
  .btn-open-book {
    padding: 10px;
    background-color:var(--btn-icon-clr);
    color: var(--nav-b-clr);
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
  }
  
  .btn-open-book:hover {
    background-color: #3498db;
  }
  

  @media (max-width: 768px) {
    .book-container {
      max-width: 600px;
      padding: 1rem 0.5rem;
    }
  
    .book-preview {
      flex-direction: column;
      padding: 20px;
      margin: 20px;
    }
  
    .book-cover img {
      width: 100px; /* Adjust the size as needed */
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .book-info {
      text-align: center;
    }
  
    .book-title {
      font-size: 1.2rem;
    }
  
    .book-description {
      font-size: 0.9rem;
    }
  
    .book-author {
      padding-bottom: 10px;
    }
  
    .btn-open-book {
      padding: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .book-container {
      max-width: 100%;
      padding: 1rem 0.5rem;
    }
  
    .book-cover img {
      width: 100%; /* Adjust the size as needed */
    }
  
    .book-title {
      font-size: 1rem;
    }
  
    .book-description {
      font-size: 0.8rem;
    }
  }
  