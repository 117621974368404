  .page-header-content img {
    object-fit: cover;
    width: 100%;
    max-height: 350px;
    min-height: 200px;
    position: relative;
    z-index: -1;
  }

  .page-header-content{
    position: relative;
    display: flex;
  }
  
  .page-header-content div {
    width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
  }
  
  .page-header-content h1 {
    font-size: 300%;
    color: aliceblue;
    font-weight: bold;
  }
  