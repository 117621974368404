.info-tab {
    display: flex;
    justify-content: flex-end;
    background-color: #2c3e50;
    color: #e7fdff;
    top: 0;
    padding: 10px;
}

.info-tab .email-us {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-tab .email-us h3 {
    margin-left: 5px
}

nav {
    background: var(--nav-b-clr);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    /* top: 0; */
    z-index: 99;
    text-transform: uppercase;
    /* box-shadow: 0 2px 10px 0 #bbbbbb; */
}


.menu-icon {
    display: none;
}

.fas {
    cursor: pointer;
}

.logo img {
    width: 200px;
    height: auto;
}

.menu-list,
.visit-us {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-list li a,
.visit-us {
    text-decoration: none;
    color: var(--lighter-secondary-logo-brand-clr);
    font-weight: bolder;
    padding: 10px;
}

.btn-visit-us {
    background-color: var(--primary-logo-brand-clr);
    border-radius: 2px;

}

.visit-us {
    color: #e7fdff;
    transition: all 0.5s ease-in-out;
    font-weight: 600;
}

.menu-list li a.active {
    color: var(--primary-logo-brand-clr);
    transition: all 0.5s ease-in-out;

}

.menu-list li a:hover {
    color: #3498db;
    transition: all 0.3s ease-in-out;
}

.visit-us:hover {
    background-color: rgb(238, 83, 0);
    color: #e7fdff;
}


@media screen and (max-width:900px) {
    .info-tab {
        font-size: 0.8rem;
    }
    nav {
        margin-top: 0;
    }

    .logo {
        font-size: 20px;
    }

    .menu-list li a,
    .btn-visit-us {
        font-size: 14px;
    }
}

@media only screen and (max-width:780px) {
    .logo img {
        width: 170px;
        height: auto;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu-icon {
        display: block;
        color: var(--primary-logo-brand-clr);
        font-size: 2rem;
    }

    .btn-visit-us {
        position: absolute;
        right: 4rem;
        font-size: 0.5rem;
    }

    .menu-list {
        flex-direction: column;
        width: 100%;
        position: absolute;
        background-color: var(--nav-b-clr);
        right: 0;
        top: 100%;
        padding: 0 0 2rem 0;
        transition: all 0.5s ease-in-out;
        text-align: center;

    }

    .menu-list li {
        margin-top: 20px;
        width: 100%;

    }


    .menu-list.close {
        display: none;
        transition: all 0.5s ease-in-out;
    }

@media only screen and (max-width:300px) {
    .logo img {
        width: 120px;
    }

    .btn-visit-us {
        font-size: .5rem;
    }

    .info-tab .email-us {
        font-size: .7rem;
    }
    
    
}    
}