.contact-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto; /* Centers the grid container */
  padding-top: 2rem;
  max-width: 1400px;
  justify-content: center;
  gap: 20px;
  }

  
  .contact-card {
    margin: 0 auto; /* Centers the grid container */
    width: 100%;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-card h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-card p {
    color: #5e5c5c;
    line-height: 1.5;
  }

  @media only screen and (max-width:780px) {
    .page-section {
      font-size: var(--mobile-font-size-text);
    }
    .contact-container  {
      grid-template-columns: repeat(2, 1fr);
    }

    .contact-card {
      margin: 0 auto; /* Centers the grid container */
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
  }

@media screen and (max-width:450px) {
  .contact-container  {
    grid-template-columns: 1fr;
    /* One column for mobile */
    /* padding: 0 2% 0 2%; Adjust padding for smaller screens */
  }
}
  
