.resources-container {
  margin: 0 auto; /* Centers the grid container */
  max-width: 800px;
  padding: 2rem .5rem 0 0.5rem;
  margin-bottom: 20px;
}

.resources-container h2 {
  text-align: center;
  margin: 20px 0;
}

/* Style for videoBooks and PDF items container */
.item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Style for individual items */
.resource-item {
  flex: 0 1 calc(50% - 20px); /* Adjust the percentage for item width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resource-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.download-link,
.download-link a{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333
}

.resource-item img {
  width: 50%;
  height: auto;
  display: block;
}

.resource-item h2 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.btn-apo-eri {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--btn-icon-clr);
  color: #e7fdff;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.btn-apo-eri:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .item-container {
    justify-content: center;
  }

  .resource-item {
    flex: 0 1 calc(100% - 20px); /* Take full width minus margin */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .item-container {
    flex-direction: column;
    align-items: center;
  }

  .resource-item {
    flex: 0 1 100%; /* Take full width */
    margin-bottom: 20px; /* Add some space between the items */
  }

  .resource-item img {
    width: 70%; /* Reduce image size on smaller screens */
  }

  .btn-apo-eri {
    padding: 8px 16px; /* Adjust button padding */
  }
}
