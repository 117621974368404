/* Footer.css */
.footer {
    background-color: #2c3e50;
    color: #e7fdff;
    padding: 40px 0;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10%;
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
    margin: 10px;
  }
  
  .footer-column h2 {
    margin-bottom: 15px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }

  .alertMsg{
    color: var(--btn-icon-clr);
    font-weight:bolder;
    padding: 10px 0;
  }
  
  .footer-column ul li a {
    color: #e7fdff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li a:hover {
    color: #3498db;
  }
  
  .footer-column .form {
    display: flex;
    gap: 10px;
  }
  
  .footer-column input[type="email"] {
    padding: 10px;
    border: none;
    outline: none;
  }
  
  .footer-column button {
    padding: 10px;
    background-color: rgb(238, 83, 0);
    border: none;
    color: #e7fdff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .footer-column button:hover {
    background-color: #3498db;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  
  .social-media a {
    color: #e7fdff;
    margin: 10px 10px 20px 10px;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .footer{
      font-size: var(--mobile-font-size-text);
    }
    .footer-top {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  }
  