/* NewsArticle.css */
.news-article {
    display: flex;
    justify-content: center;
    /* border: 1px solid #ddd; */
    margin: 0 auto; /* Centers the grid container */
    max-width: 800px;
    padding: 2rem .5rem 0 0.5rem;
    margin-bottom: 20px;
    /* background-color: #f9f9f9; */
  }

  .news-article-inner {
    margin: 0 auto; 
  }
  
  .news-title {
    margin-top: 0;
    color: #333;
    padding-bottom: 1rem;
  }
  
  .news-content {
    color: #5e5c5c;
    white-space: pre-line;
  }
  
  .news-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .news-author {
    font-style: italic;
  }
  
  .news-date {
    color: #999;
  }
